let showUI = false
let initMousePos
let endMousePos
let copiesArr = []
let resetState = false
let img, originalImage, rawImage, outputRatio
let canvasImage, canvasOutput
let canvasX, canvasY, canvasW, canvasH
let debug = false
let isDragging = false
let grid = 30
let gridOffset = grid / 2

let ui
let sidebarWidth = 0
let sliderGrid
let selectCopyMode
let selectCopyOrigin
let buttonReset
let buttonSave

let bgColor = "#333"

export function preload() {
  img = loadImage("assets/base.jpg")
  rawImage = loadImage("assets/base.jpg")
}

export function handleFileChange(file) {
  if (file.type === "image") {
    clear()
    canvasImage.clear()
    img = loadImage(file.data)
    rawImage = loadImage(file.data)

    calcInputImgSize()

    ui = createGraphics(img.width, img.height)
    canvasImage = createGraphics(img.width, img.height)
    placeImageOnCanvas(canvasImage, img)
    placeCanvas(canvasImage, img)

    outputRatio = rawImage.width / img.width
    console.log("outputRatio: ", outputRatio)

    canvasOutput = createGraphics(rawImage.width, rawImage.height)
    originalImage = createGraphics(rawImage.width, rawImage.height)

    originalImage.image(rawImage, 0, 0)
    canvasOutput.image(rawImage, 0, 0)

    setTimeout(() => {
      resetSketch()
      calcInputImgSize()
      calcCanvasPos(canvasImage)
    }, 300)
  }
}

export function setup() {
  background(bgColor)
  createCanvas(windowWidth, windowHeight, P2D)
  angleMode(DEGREES)
  pixelDensity(2)
  smooth()

  textFont("IBM Plex Mono")
  textSize(12)
  fill(255)

  const input = createFileInput(handleFileChange)
  input.position(16, height - 220)

  sliderGrid = createSlider(10, 200, 30, 10)
  sliderGrid.position(-999, 105)
  sliderGrid.style("width", "80px")

  selectCopyMode = createSelect()
  selectCopyMode.option("trail")
  selectCopyMode.option("stamp")
  selectCopyMode.selected("trail")
  selectCopyMode.position(-999, 170)
  selectCopyMode.changed(changeCopyMode)

  selectCopyOrigin = createSelect()
  selectCopyOrigin.option("original image")
  selectCopyOrigin.option("customized image")
  selectCopyOrigin.selected("original image")
  selectCopyOrigin.position(-999, 240)
  selectCopyOrigin.changed(changeCopyMode)

  // buttonReset = createButton("RESET")
  // buttonReset.position(16, 280)
  // buttonReset.mousePressed(resetSketch)

  buttonSave = createButton("EXPORT PNG (s)")
  buttonSave.position(16, height - 60)
  buttonSave.mousePressed(exportCanvas)
  buttonSave.style("background", "#fff")
  buttonSave.style("color", "#000")
  buttonSave.style("padding", "8px 16px")

  canvasOutput = createGraphics(rawImage.width, rawImage.height)
  canvasOutput.image(img, 0, 0)

  originalImage = createGraphics(rawImage.width, rawImage.height)
  originalImage.image(img, 0, 0)

  calcInputImgSize()

  outputRatio = rawImage.width / img.width
  console.log("outputRatio: ", outputRatio)

  ui = createGraphics(img.width, img.height)
  canvasImage = createGraphics(img.width, img.height)
  calcCanvasPos(canvasImage, img)
  placeImageOnCanvas(canvasImage, img) // insert chosen image inside the canvas
  placeCanvas(canvasImage, img) // place canvas
}

export function draw() {
  // console.log(mouseX, mouseY)
  grid = sliderGrid.value()
  background(bgColor)
  drawUILabels()

  cursor(ARROW)
  if (
    mouseX - canvasX < canvasW &&
    mouseX > canvasX &&
    mouseY - canvasY < canvasH &&
    mouseY > canvasY
  ) {
    cursor(CROSS)
  }

  if (resetState) {
    canvasImage.clear()
    originalImage.clear()
    canvasOutput.clear()
    background(bgColor)
    originalImage.image(rawImage, 0, 0)
    canvasOutput.image(rawImage, 0, 0)

    calcInputImgSize()
    placeImageOnCanvas(canvasImage, img)
    placeCanvas(canvasImage, img)
    calcCanvasPos(canvasImage)
    resetState = false
  }

  calcInputImgSize()
  placeCanvas(canvasImage, img)

  if (debug) {
    let l = 0
    canvasImage.push()
    calcInputImgSize()
    placeImageOnCanvas(canvasImage, img)
    canvasImage.strokeWeight(2)
    canvasImage.stroke(255, 255, 255)

    while (l < width || l < height) {
      canvasImage.line(0, l, width, l)
      canvasImage.line(l, 0, l, height)
      l += grid
    }
    canvasImage.pop()
  }

  if (showUI && isDragging == false) {
    drawSelection()
  }

  push()
  for (let i = 0; i < copiesArr.length; i++) {
    copiesArr[i].over()
    copiesArr[i].update()
    copiesArr[i].show()
  }
  pop()
}

export function drawSelection() {
  let xUI = initMousePos.x
  let yUI = initMousePos.y
  let wUI = snap(mouseX) - initMousePos.x
  let hUI = snap(mouseY) - initMousePos.y

  push()
  translate(canvasX, canvasY)
  image(ui, 0, 0)
  ui.push()
  ui.image(canvasImage, 0, 0)
  ui.translate(-canvasX, -canvasY)
  ui.fill(255, 0, 0, 20)
  ui.stroke(255, 0, 0)
  ui.strokeWeight(2)
  ui.rect(xUI, yUI, wUI, hUI)
  ui.pop()
  pop()
}

export function mousePressed() {
  initMousePos = createVector(snap(mouseX), snap(mouseY))
  // console.log("---\nstart mouse pos:", initMousePos.x, initMousePos.y)
  showUI = true
  ui.clear()

  if (copiesArr.length > 0) {
    copiesArr[copiesArr.length - 1].pressed()
  }
}

export function mouseReleased() {
  endMousePos = createVector(snap(mouseX), snap(mouseY))
  // console.log("end mouse pos:", endMousePos.x, endMousePos.y, "\n---\n")

  // preview image
  let prevX =
    initMousePos.x < mouseX
      ? int(snap(initMousePos.x) - canvasX)
      : snap(mouseX) - canvasX

  let prevY =
    initMousePos.y < mouseY
      ? int(snap(initMousePos.y) - canvasY)
      : snap(mouseY) - canvasY

  let prevW = int(abs(endMousePos.x - initMousePos.x))
  let prevH = int(abs(endMousePos.y - initMousePos.y))
  let prevCopy = createGraphics(prevW, prevH)

  //output
  let outX = int(prevX * outputRatio)
  let outY = int(prevY * outputRatio)
  let outW = int(prevW * outputRatio)
  let outH = int(prevH * outputRatio)
  let outputCopy = createGraphics(outW, outH)

  console.log(
    "outX: " + outX + " outY: " + outY + " outW: " + outW + " outH: " + outH
  )

  // if selection not empty, create copy of selection
  if (isDragging == false && prevW > 0 && prevH > 0) {
    // console.log(
    //   "--- \n new copy \n width:" +
    //     prevW +
    //     " height:" +
    //     prevH +
    //     " x:" +
    //     prevX +
    //     " y:" +
    //     prevY +
    //     "\n---\n"
    // )

    // preview
    prevCopy.copy(
      selectCopyOrigin.value() == "original image" ? img : canvasImage,
      prevX,
      prevY,
      prevW,
      prevH,
      0,
      0,
      prevW,
      prevH
    )

    // output
    outputCopy.copy(
      selectCopyOrigin.value() == "original image"
        ? originalImage
        : canvasOutput,
      outX,
      outY,
      outW,
      outH,
      0,
      0,
      outW,
      outH
    )

    copiesArr.push(
      new dragCopy(
        prevCopy,
        prevX,
        prevY,
        prevW,
        prevH,
        outputCopy,
        outX,
        outY,
        outW,
        outH
      )
    )
  }

  ui.clear()
  showUI = false

  if (copiesArr.length > 0) {
    copiesArr[copiesArr.length - 1].released()
  }
}

class dragCopy {
  constructor(prevCopy, x, y, w, h, outCopy, outX, outY, outW, outH) {
    this.dragging = false
    this.rollover = false
    this.img = prevCopy
    this.copy = prevCopy
    this.x = x
    this.y = y
    this.w = w
    this.h = h
    this.outCopy = outCopy
    this.outX = outX
    this.outY = outY
    this.outW = outW
    this.outH = outH
    this.initX = 0
    this.initY = 0
  }

  over() {
    if (
      mouseX - canvasX > this.x &&
      mouseX - canvasX < this.x + this.w &&
      mouseY - canvasY > this.y &&
      mouseY - canvasY < this.y + this.h
    ) {
      console.log("---\nrolled over\n---")
      this.rollover = true

      // show UI when hover a copy
      cursor("grab")
      image(ui, canvasX, canvasY)
      ui.push()
      ui.image(canvasImage, 0, 0)
      ui.fill(255, 0, 0, 10)
      ui.stroke(255, 0, 0)
      ui.strokeWeight(2)
      ui.rect(this.x, this.y, this.w, this.h)
      ui.pop()
    } else {
      this.rollover = false
    }
  }

  update() {
    this.initX = this.x
    this.initY = this.y

    this.initOutX = this.outX
    this.initOutY = this.outY

    if (this.dragging) {
      this.x = snap(mouseX - canvasX + this.offsetX)
      this.y = snap(mouseY - canvasY + this.offsetY)

      console.log("--- preview")
      console.log("this.x: " + this.x + " this.y: " + this.y)
      console.log("--- output")
      console.log("this.outX: " + this.outX + " this.outY: " + this.outY)

      this.outX = this.x * outputRatio
      this.outY = this.y * outputRatio
    }
  }

  pressed() {
    if (
      mouseX - canvasX > this.x &&
      mouseX - canvasX < this.x + this.w &&
      mouseY - canvasY > this.y &&
      mouseY - canvasY < this.y + this.h
    ) {
      ui.clear()
      this.dragging = true
      isDragging = true
      this.offsetX = this.x - (mouseX - canvasX)
      this.offsetY = this.y - (mouseY - canvasY)
      this.outOffX = this.offsetX * outputRatio
      this.outOffY = this.offsetY * outputRatio
    }
  }

  released() {
    this.dragging = false
    isDragging = false
    copiesArr = copiesArr.slice(-1)

    setTimeout(() => {
      if (isDragging == false) {
        copiesArr = []
      }
    }, 1000)
  }

  show() {
    if (selectCopyMode.value() === "trail") {
      let trail = floor(dist(this.initX, this.initY, this.x, this.y))
      let outTrail = floor(
        dist(this.initOutX, this.initOutY, this.outX, this.outY)
      )
      let dir = createVector(
        (this.x - this.initX) / trail,
        (this.y - this.initY) / trail
      )

      let outDir = createVector(
        (this.outX - this.initOutX) / outTrail,
        (this.outY - this.initOutY) / outTrail
      )

      for (let j = 0; j < trail; j++) {
        canvasImage.image(
          this.img,
          this.initX + dir.x * j,
          this.initY + dir.y * j,
          this.w,
          this.h
        )
      }

      for (let j = 0; j < outTrail; j++) {
        canvasOutput.image(
          this.outCopy,
          this.initOutX + outDir.x * j,
          this.initOutY + outDir.y * j,
          this.outW,
          this.outH
        )
      }
    } else {
      // stamp mode
      canvasImage.image(this.img, this.x, this.y, this.w, this.h)
      canvasOutput.image(
        this.outCopy,
        this.outX,
        this.outY,
        this.outW,
        this.outH
      )
    }
  }
}

export function keyPressed() {
  // console.log(key)
  if (keyCode === ENTER) {
    console.log("-- \n reseted sketch \n-- ")
    resetSketch()
  } else if (keyCode === SHIFT) {
    changeCopyOrigin()
  } else if (key === "d") {
    debug = !debug
    console.log("debug: " + debug)
  } else if (key === "m") {
    changeCopyMode()
  } else if (key == "s") {
    exportCanvas("PNG")
  } else if (key == "+" || key == "=") {
    sliderGrid.value(sliderGrid.value() + 10)
  } else if (key == "-") {
    if (grid >= 10) {
      sliderGrid.value(sliderGrid.value() - 10)
    }
  }
}

export function changeCopyMode() {
  let currVal = selectCopyMode.value()
  console.log("current mode: ", selectCopyMode.value())
  currVal == "trail"
    ? selectCopyMode.selected("stamp")
    : selectCopyMode.selected("trail")

  console.log("new mode: ", selectCopyMode.value())
}

export function changeCopyOrigin() {
  let currVal = selectCopyOrigin.value()
  console.log("current copy origin: ", selectCopyOrigin.value())
  currVal == "original image"
    ? selectCopyOrigin.value("customized image")
    : selectCopyOrigin.value("original image")

  console.log("new copy origin: ", selectCopyOrigin.value())
}

export function calcInputImgSize() {
  img.resize(0, windowHeight - 50)
}

export function resetSketch() {
  resetState = true
  copiesArr = []
}

export function placeImageOnCanvas(canvas, img) {
  canvas.image(img, 0, 0, img.width, img.height)
}

export function placeCanvas(canvas) {
  push()
  strokeWeight(2)
  noFill()
  rect(canvasX, canvasY, canvasW, canvasH)
  noStroke()
  fill(0, 5)
  rect(canvasX + 5, canvasY + 5, canvasW, canvasH)
  pop()
  image(
    canvas,
    width / 2 - canvas.width / 2 + sidebarWidth / 2,
    height / 2 - canvas.height / 2
  )

  // image(
  //   canvasOutput,
  //   width / 2 - canvas.width / 2 + canvas.width + 100,
  //   height / 2 - canvas.height / 2
  // )
}

export function calcCanvasPos(canvas) {
  canvasX = width / 2 - canvas.width / 2 + sidebarWidth / 2
  canvasY = height / 2 - canvas.height / 2
  canvasW = canvas.width
  canvasH = canvas.height
}

export function exportCanvas() {
  let timestamp =
    year() +
    nf(month(), 2) +
    nf(day(), 2) +
    "-" +
    nf(hour(), 2) +
    nf(minute(), 2) +
    nf(second(), 2)

  saveCanvas(canvasOutput, timestamp, "png")
}

export function snap(op) {
  // snap to grid
  var cell = Math.round((op - gridOffset) / grid)
  return cell * grid + gridOffset
}

export function windowResized() {
  setTimeout(() => {
    resizeCanvas(windowWidth, windowHeight)
    calcInputImgSize()
    calcCanvasPos(canvasImage, img)
  }, 10)
}

export function drawUILabels() {
  push()
  fill(255)
  text("change image", 16, height - 240)
  text("grid size (+/-): " + grid, 16, height - 145)
  text("copy mode (m): " + selectCopyMode.value(), 16, height - 125)
  text("copy origin (shift): " + selectCopyOrigin.value(), 16, height - 105)
  text("reset image (enter)", 16, height - 85)
  pop()
}
